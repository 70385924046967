<template>
    <layout class="periodical">
        <!-- 面包屑 -->
        <div class="container mt15 mobile-bread flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/'}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/journal-article'}">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/periodical-list'}">期刊杂志</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:''}">杂志详情</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container flex mt30">
            <div class="meeting-report">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 期刊内容 -->
                    <div v-if="info" class="paper-detail mh flex x-left">
                        <img class="img" :src="info.img_url | urlFilter(250)" alt="">
                        <div class="flex-column paper-detail-content x-between">
                            <div class="flex x-left">
                                <div class="t-l ml20 paper-detail-name">
                                    <div class="fs16 color333 strong">{{info.cn_name}}</div>
                                    <div class="fs14 color999 mt10" v-if="info.en_name">{{info.en_name}}</div>
                                </div>
                                <div class="t-l ml20 fs14 color333">
                                    <div v-if="info.org" style="line-height:24px;"><span class="color999">主办单位：</span>{{info.org}}</div>
                                    <div v-if="info.cn_code" class="mt10"><span class="color999">国内期刊：</span>{{info.cn_code}}</div>
                                </div>
                            </div>
                            <div class="t-l paper-detail-year ml20">
                                <div class="fs16 color333 t-l strong mb10">年份刊次</div>
                                <year-list :list="yearList" @getNumList="getNumList"></year-list>
                                <year-list type="2" :list="paperFilter" class="mt10" @getNumList="paperNum"></year-list>
                            </div>
                        </div>
                    </div>
                    <!-- 刊次列表 -->
                    <div class="mt20" v-if="paperList.length">
                        <div class="papaer-item pt15 pb20 t-l" v-for="(item,index) in paperList" :key="index" @click="getPaperDetail(item.id,item.type)">
                            
                            <span class="fs16 color333 strong">{{item.title}}</span>
                            <span class="fs12 color666 row-1 mt10" v-if="item.author">作者：<span class="strong color333">{{item.author}}</span></span>
                            <span v-if="item.page" class="fs12 color666 row-1 mt10">页码：{{item.page}}</span>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!paperList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="paperCount > 0">
                    <a-pagination :total="paperCount" v-model:current="paperParams.page"
                        :page-size="paperParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import journalItem from '../../components/journal-item.vue';
import filterTab from '@/components/filter-tab.vue';
import yearList from '@/components/year-list.vue';
import { jsonFormat } from '@/utils/jsonFormat.js';
import storage from 'store'
export default {
    name: 'index',
    components: {
        layout,
        layoutRight,
        filterTab,
        yearList,
        journalItem
    },
    data() {
        return {
            jou_id:this.$route.query.id,
            // jou_id:'J00303',
            paperDetail:{},
            paperFilter:[], //刊次
            yearList:[], //刊次
            info:null,
            isShowMore: true,
            paperParams: {
                page: 1,
                limit: 10,
            },
            paperCount: 0,
            paperList:[], //刊次列表
            year:'',
            filter_num:'',
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods:'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            loading: true,
        }
    },
    created() {
        this.getPeriodicalDetail()
    },
    methods: {
        // 根据年份切换期数
        getNumList(year){
            this.year = year
            for(let key in this.paperDetail.year){
                if(key == year){
                    this.paperFilter = this.paperDetail.year[key]
                }
            }
            this.paperParams.page = 1
            this.getPaperList()
        },
        paperNum(page){
            this.paperParams.page = 1
            this.filter_num = page
            this.getPaperList()
        },
        // 期刊杂志详情
        getPeriodicalDetail() {
            this.loading = true
            this.request.post('PeriodicalDetail', {jou_id: this.jou_id}).then(res => {
                this.paperDetail = res.data
                // 无数据
                if(!this.paperDetail){
                    this.loading = false;
                    return false;
                }
                this.info = res.data.info
                let list = []
                for(let key in this.paperDetail.year){
                    list.unshift(key)
                }
                this.yearList = list
                let filter = Object.values(this.paperDetail.year)
                this.paperFilter = filter[filter.length-1]
                let yearList = Object.keys(this.paperDetail.year)
                this.year = yearList[yearList.length - 1]
                this.filter_num = this.paperFilter[0]
                this.getPaperList()
                
            })
        },
        // 刊次列表
        getPaperList(){
            let data = {
                jou_id: this.jou_id,
                per: this.filter_num,
                year: this.year,
            }
            this.loading = true;
            this.request.post('PeriodicalPaper',data).then(res=>{
                this.paperCount = res.data.length
                this.paperList = res.data.slice(this.paperParams.page*10-10,this.paperParams.page*10);
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.paperParams.page = page
            this.getPaperList()
        },
        getPaperDetail(id,type){
            console.log(id,type)
            this.$router.push({
                path : '/paper-detail',
                query : {
                    id:id,
                    type:type
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.periodical {
    .filter-box:not(:last-child) {
        border-bottom: 0;
    }

    .filter-condition {
        background: #f2f4fa;
        overflow: hidden;
        height: 8px;

        &.active {
            height: 50px;
            line-height: 50px;
        }
    }

    .meeting-report {
        width: calc(66.66% - 30px);

        .mh {
            // min-height: 500px;
        }
        .paper-detail{
            .img{
                width: 160px;
                height: 200px;
            }
            &-name{
                max-width:300px;
            }
        }
        .papaer-item{
            cursor: pointer;
            border-top: 1px dashed #D8D8D8;
        }
        .paper-year{
            position: relative;
            overflow: hidden;
            .swiper-content{
                flex-wrap:nowrap;
                transition: all 0.15s linear;
            }
            .btn-direction{
                cursor: pointer;
                position: absolute;
                top:50%;
                width:30px;
                height:45px;
                line-height: 45px;
                text-align: center;
                margin-top:-22px;
                background-color: @theme;
                color:#fff;
                border-radius: 30px;
                font-size:16px;
                z-index:2;
                &.disabled{
                    opacity: 0.3;
                }
                &.med-left{
                    left:0;
                }
                &.med-right{
                    right:0;
                }
            }
        }
    }

    .meeting-right {
        width: 33.33%;
    }
}


@media screen and (max-width:768px){
    .periodical{
        .container{
            display: block;
            padding:0 10px;
        }
        .mobile-bread{
            display: flex;
        }
        .guide-list-left,.guide-list-right,.meeting-right{
            width: 100%;
            padding-top:20px;
        }
        .meeting-report{
            width: 100%;
        }
    }
    .paper-detail{
        display: block;
        &-content{
            margin-top:20px;
        }
        &-name{
            margin-left:0;
        }
        &-year{
            margin-left:0;
            margin-top:20px;
        }
    }
    .year-list{
        width: 100%;
    }
}
</style>
